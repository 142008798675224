<template>
  <div>
    <TextField v-if="type === 'text'" :label="label" @input="emitNewInput" :value="value ? value : null" />
    <NumberField v-if="type === 'number'" :label="label" @input="emitNewInput" :value="value ? value : null" />
    <DateField v-if="type === 'date'" :label="label" @input="emitNewInput" :value="value ? value : null" />
    <CheckboxField
      v-if="type === 'checkbox'"
      :label="label"
      @input="emitNewInput"
      :value="value ? value : null"
    />
    <TextareaField
      v-if="type === 'textarea'"
      :label="label"
      :min_length="this.min_length"
      @input="emitNewInput"
      :error_message="error_message ? error_message : null"
      :value="value ? value : null"
    />
    <TimeField
      v-if="type === 'time_full'"
      :label="label"
      :prep_message="prep_message"
      @input="emitNewInput"
      :value="value ? value : null"
    />
    <TimeSingleField
      v-if="type === 'time_single'"
      :label="label"
      @input="emitNewInput"
      :value="value ? value : null"
    />
    <TimeDoubleField
      v-if="type === 'time_double'"
      :label="label"
      @input="emitNewInput"
      :value="value ? value : null"
    />
    <DropboxField
      v-if="type === 'dropbox' || type === 'select'"
      :label="label"
      :options="options"
      :hidden_content="hidden_content"
      :multiple="multi_select"
      @input="emitNewInput"
      :value="value ? value : null"
    />
    <MultiDropboxField
      v-if="type === 'multi_dropdown'"
      :label="label"
      :options="options"
      @input="emitNewInput"
      :value="value ? value : null"
    />
    <SearchDropboxField
      v-if="type === 'dropdown'"
      :label="label"
      :options="options"
      :hidden_content="hidden_content"
      @input="emitNewInput"
      @hiddenInput="emitNewHiddenInput"
      :value="value ? value : null"
      :child_fields="child_fields"
      :value_map="value_map"
      :error_message="error_message ? error_message : null"
    />
    <AttendanceField
      v-if="type === 'attendance'"
      :label="label"
      @input="emitNewInput"
      :value="value ? value : null"
    />
    <LookupField
      v-if="type === 'lookup'"
      :label="label"
      :categories="categories"
      :categories_label="category_label"
      :max_input="max_length"
      @input_data="emitNewInput"
      @hidden_data="emitNewHiddenInput"
      :value="value ? value : null"
      :child_fields="child_fields"
    />
    <SectionField
      v-if="type === 'section'"
      :label="label"
      :child_fields="child_fields"
      :value_map="value_map"
      @input="emitNewInput"
      @hiddenInput="emitNewHiddenInput"
      :value="value ? value : null"
    />
    <DatetimeField
      v-if="type === 'datetime'"
      :label="label"
      @input="emitNewInput"
      :value="value ? value : null"
    />
  </div>
</template>

<script>
import TextField from "@/components/forms/fields/TextField.vue";
import DateField from "@/components/forms/fields/DateField.vue";
import CheckboxField from "@/components/forms/fields/CheckboxField.vue";
import TextareaField from "@/components/forms/fields/TextareaField.vue";
import TimeField from "@/components/forms/fields/TimeField.vue";
import TimeSingleField from "@/components/forms/fields/TimeSingleField.vue";
import DropboxField from "@/components/forms/fields/DropboxField.vue";
import TimeDoubleField from "@/components/forms/fields/TimeDoubleField";
import MultiDropboxField from "@/components/forms/fields/MultiDropboxField";
import SearchDropboxField from "@/components/forms/fields/SearchDropboxField";
import AttendanceField from "@/components/forms/fields/AttendanceField";
import LookupField from "@/components/forms/fields/LookupField";
import SectionField from "@/components/forms/fields/SectionField.vue";
import NumberField from "@/components/forms/fields/NumberField.vue";
import DatetimeField from "@/components/forms/fields/DatetimeField";
export default {
  name: "RenderField",
  components: {
    LookupField,
    AttendanceField,
    TimeDoubleField,
    TextField,
    DateField,
    CheckboxField,
    TextareaField,
    TimeField,
    TimeSingleField,
    DropboxField,
    MultiDropboxField,
    SearchDropboxField,
    SectionField,
    NumberField,
    DatetimeField,
  },
  emits: ["emitNewInput", "emitNewHiddenInput"],
  props: {
    type: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: false,
    },
    hidden_content: {
      type: Array,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    multi_select: {
      type: Boolean,
      required: false,
      default: false,
    },
    max_length: {
      type: Number,
      required: false,
    },
    min_length: {
      type: Number,
      required: false,
    },
    error_message: {
      type: String,
      required: false,
    },
    prep_message: {
      type: String,
      required: false,
    },
    category_label: {
      type: String,
      required: false,
    },
    categories: {
      type: Object,
      required: false,
    },
    field_id: {
      type: String,
      required: true,
    },
    child_fields: {
      type: Array,
      required: false,
    },
    value: {
      required: false,
    },
    value_map: {
      type: Object,
      required: false,
    },
  },
  methods: {
    emitNewInput(input) {
      this.$emit("emitNewInput", { ...input, field_id: this.field_id });
    },
    emitNewHiddenInput(input) {
      this.$emit("emitNewHiddenInput", input);
    },
  },
};
</script>

<style scoped></style>
