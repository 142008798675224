<!--This component is recursive-->
<!--This may not be needed anymore, more to come later-->
<template>
  <div class="py-3">
    <select class="rounded" v-model="input" :multiple="multiple">
      <option v-for="option in options" :key="option" :value="option">
        {{ option }}
      </option>
    </select>
    <div v-for="content in hidden_content" :key="content.label">
      <div v-if="content.parent === input" class="pt-3">
        <div class="thirdhead-text">{{ content.label }}</div>
        <div>{{ content.description }}</div>
        <RenderField
          :type="content.type"
          :options="'options' in content ? content.options : null"
          :hidden_content="
            'options' in content && 'hidden_content' in content
              ? content.hidden_content
              : null
          "
          :label="content.label"
          @emitNewInput="newHiddenInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RenderField from "@/components/forms/RenderField";
export default {
  name: "DropboxField",
  components: {
    RenderField, // declaring it here is optional, but it removes warnings in the template
  },
  emits: ["input"],
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    hidden_content: {
      type: Array,
      required: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  // Required for recursive component calling
  beforeCreate() {
    this.$options.components.RenderField = RenderField;
  },
  data() {
    return {
      input: null,
    };
  },
  methods: {
    newHiddenInput(newHiddenValue) {
      this.$emit("input", {
        value: newHiddenValue.value,
        label: newHiddenValue.label,
      });
    },
  },
  watch: {
    input(newValue) {
      this.$emit("input", {
        label: this.label,
        value: newValue,
      });
    },
  },
};
</script>

<style scoped></style>
