<!--WILL ONLY BE USED IN THE PPDP MEETING ENTRY-->
<template>
  <div class="grid grid-cols-2 gap-0 py-3">
    <div class="flex-none pt-4 font-bold">Travel Time:</div>
    <div>
      <input
        type="text"
        class="rounded text-base w-16"
        placeholder="0.00"
        v-model="travel_time"
        :id="label.replace(/[^A-Z0-9]+/ig, '_') + '-travel_time'"
      />
      <label class="sr-only" :for="label.replace(/[^A-Z0-9]+/ig, '_') + '-travel_time'">{{ label + '-travel_time' }}</label>
      <div class="pt-2 flex text-xs text-steel-gray w-3/4">
        <div v-if="travel_time % 0.25 !== 0" class="justify-self-start pb-2">
          <div class="rounded border border-error-border bg-error-bg text-error-text p-1">
            {{ error_message }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex-none pt-4 font-bold">PPDP Meeting Time:</div>
    <div>
      <input
        type="text"
        class="rounded text-base w-16"
        placeholder="0.00"
        v-model="event_time"
        :id="label.replace(/[^A-Z0-9]+/ig, '_') + '-event_time'"
      />
      <label class="sr-only" :for="label.replace(/[^A-Z0-9]+/ig, '_') + '-event_time'">{{ label + '-event_time' }}</label>
      <div class="pt-2 flex text-xs text-steel-gray w-3/4">
        <div v-if="event_time % 0.25 !== 0" class="justify-self-start pb-2">
          <div class="rounded border border-error-border bg-error-bg text-error-text p-1">
            {{ error_message }}
          </div>
        </div>
      </div>
    </div>
    <div class="border-t flex-none py-4 font-bold">Total:</div>
    <div class="border-t pt-4 w-16 px-3">
      {{ +travel_time + +event_time }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeDoubleField",
  emits: ["input"],
  props: {
    label: {
      type: String,
      required: true,
    },
    error_message: {
      type: String,
      default: "Please enter time in 0.25 hour increments.",
    },
  },
  data() {
    return {
      travel_time: null,
      event_time: null,
    };
  },
  watch: {
    travel_time: function (val) {
      this.$emit("input", {
        value: {
          prep_time: 0,
          travel_time: val,
          event_time: this.event_time,
        },
        label: this.label,
      });
    },
    event_time: function (val) {
      this.$emit("input", {
        value: {
          prep_time: 0,
          travel_time: this.travel_time,
          event_time: val,
        },
        label: this.label,
      });
    },
  },
};
</script>

<style scoped></style>
