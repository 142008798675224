<template>
  <div class="py-3">
    <div>Was your mentor present for this experience?</div>
    <div>
      <div>
        <SearchDropboxField
          :options="['Yes', 'No']"
          label="Mentor Present"
          @input="setAttendance"
        />
      </div>
      <div v-if="debrief_mentor_present === 'Yes'">
        <div class="thirdhead-text">
          <label for="debrief_mentor_present_who">Mentor Name(s)</label>
        </div>
        <MultiDropboxField
          label="Mentor Present"
          :options="['Mentor', 'Mentee', 'Elementor']"
          id="debrief_mentor_present_who"
          @input="pushDebriefMentorPresent"
        />
      </div>
      <PopupWOverlay
        v-if="debrief_mentor_present === 'No' && warning"
        :content="content"
        @continue="warning = false"
      />
    </div>
    <!--              <div v-if="field.show_format">
      <div class="pb-2">What was the format for this experience?</div>
      <div class="pb-3">
        <div>
          <input name="experience-format" type="radio" class="mb-2" />
          In-person
        </div>
        <div>
          <input name="experience-format" type="radio" class="mb-2" />
          Phone conference
        </div>
        <div>
          <input name="experience-format" type="radio" class="mb-2" />
          Video conference
        </div>
        <div>
          <input name="experience-format" type="radio" class="mb-2" />
          Email
        </div>
      </div>
    </div>
    <div v-if="field.show_hosted">
      <div class="pb-2">
        Was the experience hosted at St. Thomas Law?
      </div>
      <div class="">
        <div>
          <input name="hosted" type="radio" class="mb-2" />
          Yes
        </div>
        <div>
          <input name="hosted" type="radio" class="mb-2" />
          No
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import MultiDropboxField from "@/components/forms/fields/MultiDropboxField";
import PopupWOverlay from "@/components/displays/alert/PopupWOverlay";
import SearchDropboxField from "@/components/forms/fields/SearchDropboxField";
export default {
  name: "AttendanceField",
  components: { SearchDropboxField, PopupWOverlay, MultiDropboxField },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      debrief_mentor_present: "",
      warning: true,
      content:
        "Debriefs are face-to-face conversations with your mentor. If\n" +
        "                this conversation was with another attorney/judge, it may\n" +
        "                qualify as a Networking conversation or as part of an experience\n" +
        "                with that attorney/judge.",
    };
  },
  watch: {
    debrief_mentor_present: function (val) {
      this.$emit("input", {
        value: val,
        label: this.label,
      });
    },
  },
  methods: {
    pushDebriefMentorPresent: function (val) {
      // console.log(val.value);
      this.$emit("input", { label: "Mentor Name(s)", value: val.value });
    },
    setAttendance: function (val) {
      this.debrief_mentor_present = val.value;
    },
  },
};
</script>

<style scoped></style>
