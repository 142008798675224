<template>
  <div class="py-3">
    <input type="date" class="rounded" v-model="input" :id="label.replace(/[^A-Z0-9]+/ig, '_')" />
    <label class="sr-only" :for="label.replace(/[^A-Z0-9]+/ig, '_')">{{ label }}</label>
  </div>
</template>

<script>

export default {
  name: "DateField",
  emits: ["input"],
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    }
  },
  data() {
    return {
      input: null,
    };
  },
  created() {
    this.input = this.value ? this.value[0] : null;
  },
  watch: {
    input(newValue) {
      this.$emit("input", { value: newValue, label: this.label });
    },
  },
};
</script>

<style scoped></style>
