<template>
  <div class="flex py-3">
    <div>
      <input type="checkbox" :id="label.replace(/[^A-Z0-9]+/ig, '_')" class="rounded" v-model="input" />
    </div>
    <div class="px-3">
      <label :for="label.replace(/[^A-Z0-9]+/ig, '_')" class="thirdhead-text">{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxField",
  emits: ["input"],
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
  },
  data() {
    return {
      input: null,
    };
  },
  created() {
    this.input = Boolean(this.value);
  },
  watch: {
    input(newValue) {
      this.$emit("input", { value: newValue, label: this.label });
    },
  },
};
</script>

<style scoped></style>
