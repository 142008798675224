<template>
  <div>
    <div class="flex py-3">
      <div class="pt-1">
        <button
          :aria-label="'open_modal_' + categories_label"
          class="inline-block py-1 mr-4 px-2.5 bg-purple hover:bg-dark-purple rounded-full text-white text-sm"
          @click.prevent="toggleModal"
        >
          Lookup
        </button>
      </div>
    </div>
    <div>
      <draggable-list
        v-model="input_array"
        list_of="experiences"
        identifier="id"
        @remove="removeModalSelection"
      />
    </div>
    <div class="flex flex-col" v-if="show_wildcard_label_input">
      <div class="fourthhead-text py-2">{{ wild_or_director }} Title</div>
      <input
        type="text"
        id="wild_label"
        :class="{
          'border p-1 border-steel-gray rounded h-8 w-3/4 mr-3': true,
        }"
        v-model="wildcard_label"
        placeholder="Enter experience title"
      />
      <label class="sr-only" for="wild_label"
        >{{ wild_or_director }} Title</label
      >
    </div>
    <!-- This should take "dyn-categories" as the default. -->
    <LookupModal
      v-if="is_open"
      :categories="dyn_categories ? dyn_categories : categories"
      :label="categories_label"
      @closeModal="toggleModal"
      @addSelection="pushSelection"
      :max_input="1"
    />
  </div>
</template>

<script>
import LookupModal from "@/components/lookup/LookupModal";
import DraggableList from "../../draggable/DraggableList";
import { useStore } from "vuex";

export default {
  name: "LookupField",
  components: {
    LookupModal,
    DraggableList,
  },
  props: {
    categories: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    categories_label: {
      type: String,
      required: true,
    },
    max_input: {
      type: Number,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    child_fields: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      is_open: false,
      input_array: [],
      wildcard_label: "",
      show_wildcard_label_input: false,
      wild_or_director: "",
      selected_id: null,
    };
  },
  setup(props) {
    // console.log("Setting up LookupField");
    const store = useStore();
    let dyn_categories = {};
    if (props.label.toLowerCase().includes("experience")) {
      store.getters.lookup_experiences.forEach((type) => {
        type.categories.forEach((cat) => {
          dyn_categories[cat.description] = {
            name: cat.description,
            items: cat.experiences,
            open: true,
          };
        });
      });
      // sort keys of dyn_categories alphabetically
      dyn_categories = Object.keys(dyn_categories)
        .sort()
        .reduce((obj, key) => {
          obj[key] = dyn_categories[key];
          obj[key].items.sort((a, b) => a.description > b.description);
          return obj;
        }, {});
    } else {
      console.log("No experience categories");
    }
    return {
      dyn_categories,
    };
  },
  mounted() {
    if (this.value) {
      this.value.forEach((value) => {
        for (const cat in this.dyn_categories) {
          this.dyn_categories[cat].items.forEach((item) => {
            if (item.description === value) {
              this.pushSelection({ selection: [item] });
            }
          });
        }
      });
    }
  },
  methods: {
    toggleModal: function () {
      this.is_open = !this.is_open;
    },
    pushSelection: function (selection) {
      this.input_array = selection.selection;
      this.is_open = false;
    },
    removeModalSelection: function (index) {
      this.max_input > 1
        ? this.input_array.splice(index, 1)
        : (this.input_array = []);
      this.selected_id = null;
    },
  },
  watch: {
    input_array: function (new_input) {
      let flag = false;
      let db_input = [];
      new_input.forEach((item) => {
        if (
          item.description === "Director-Approved Experience" ||
          item.description === "Wildcard Experience"
        ) {
          this.wild_or_director = item.description;
          this.show_wildcard_label_input = true;
          flag = true;
        }
      });
      if (!flag) {
        this.wild_or_director = "";
        this.show_wildcard_label_input = false;
        this.wildcard_label = "";
      }
      new_input.forEach((item) => {
        db_input.push(item.description);
        this.selected_id = item.experience_id;
      });
      this.$emit("input_data", {
        value: db_input,
        label: this.label,
      });
      this.$emit("hidden_data", {
        value: this.selected_id,
        label: this.child_fields[0].name,
        field_id: this.child_fields[0].field_id,
      });
    },
    wildcard_label: function (new_label) {
      this.$emit("input_data", {
        value:
          this.wild_or_director + ": " + new_label.replace(" Experience", ""),
        label: "Experience Type",
      });
    },
  },
};
</script>

<style scoped></style>
