<template>
  <div class="py-3">
    <input
      type="number"
      class="rounded border-steel-gray w-1/2"
      v-model="input"
      :id="label.replace(/[^A-Z0-9]+/ig, '_')"
      step="0.25"
      min="0"
    />
    <label class="sr-only" :for="label.replace(/[^A-Z0-9]+/ig, '_')">{{ label }}</label>
    <div class="flex text-xs text-steel-gray w-3/4">
      <div v-if="input % 0.25 !== 0" class="justify-self-start pt-1">
        <div
          class="rounded border border-error-border bg-error-bg text-error-text p-1"
        >
          Please enter time in 0.25 hour increments.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberField",
  emits: ["input"],
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
  },
  data() {
    return {
      input: null,
    };
  },
  created() {
    this.input = Number(this.value) || 0.0;
  },
  // watch for change in input and emit event
  watch: {
    input(newValue) {
      this.$emit("input", { value: newValue, label: this.label });
    },
  },
};
</script>

<style scoped></style>
