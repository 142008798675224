<template>
  <div
    class="box-style max-w-1/2 w-1/2 border border-light-gray absolute hover:cursor-pointer z-[110]"
  >
    <div class="relative">
      <div class="-mx-5 -mt-3">
        <div class="thirdhead-text px-5">
          {{ label }}
        </div>
      </div>
      <div>
        <div class="pb-3">
          <div class="text-base pt-3 pb-1">Browse Categories</div>
          <div class="flex">
            <div class="flex-initial w-1/2">
              <select
                class="w-full h-8 p-1 rounded text-sm"
                @change="setCategory($event.target.value)"
              >
                <option value="All">All Categories</option>
                <option
                  v-for="(category, idx) in categories"
                  :key="idx"
                  :value="category.name"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
            <div class="flex-initial w-1/2 ml-2">
              <input
                type="text"
                class="border w-full h-8 p-1 rounded text-sm focus:outline-transparent"
                v-model="query"
                placeholder="Search..."
              />
              <button
                aria-label="search"
                class="flex-none justify-end items-center py-1"
                @click="toggleTextInput"
              >
              </button>
            </div>
          </div>
        </div>
        <div class="border border-cool-gray mb-3" />
        <div class="pill-scroll h-80 overscroll-auto overflow-y-auto bg-white">
          <div v-for="(category, idx) in filtered_categories" :key="idx">
            {{ filtered_categories.length }}
            <div
              v-if="
                selected_category === 'All' ||
                selected_category === category.name
              "
              class="pb-3 font-bold"
            >
              <div @click="toggleCategory(idx)" class="pb-1">
                {{ category.name }}
                <span class="px-1"
                  ><svg
                    :class="{
                      'svg-inline--fa fa-chevron-down fa-w-14 mt-1': true,
                      'rotate-180': category.open,
                    }"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-down"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      class=""
                      fill="currentColor"
                      d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                    ></path></svg
                ></span>
              </div>
              <div v-if="category.open">
                <div
                  v-for="item in category.items"
                  :key="item"
                  class="px-0.5 font-normal pb-1 flex"
                >
                  <div class="pt-0.5">
                    <input
                      type="checkbox"
                      :id="item.description"
                      @click="pushItem(item)"
                      class="rounded disabled:bg-light-gray"
                      :disabled="
                        selected.length === max_input &&
                        !selected.filter((obj) => {
                          return obj.description === item.description;
                        }).length
                      "
                    />
                  </div>
                  <label :for="item.description" class="px-2">{{
                    item.description
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border border-cool-gray mb-3" />
        <div class="relative pb-5">
          <button
            aria-label="add_modal_selection"
            class="inline-block py-1 mr-4 px-2.5 bg-purple hover:bg-dark-purple rounded-full text-white text-sm absolute -right-3"
            @click="addSelected"
          >
            Add
          </button>
        </div>
        <button
          aria-label="close_modal"
          class="absolute top-0 right-0 pb-3 text-2xl font-bold text-dark-purple"
          @click="closeModal"
        >
          &times;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/*Lookup Modal Reusable Component Use Instructions:
 * Matan Gazit
 * Use: <template>
 *       ...
 *        <LookupModal :categories="selection_cats" @addSelection="method" @closeModal="method/expr" />
 *       ...
 *      </template>
 *
 * Props: categories=Object
 *        model: { category_a: {
 *                      name: 'Category A',
 *                      open: false, // always false by default
 *                      items: [
 *                              {name: "item_a", value: a},
 *                              {name: "item_b", value: b},
 *                              {name: "item_c", value: c}
 *                            ]
 *                    },
 *               } // model is an object of category objects
 * Emitters: addSelection = adds user selected options to the parent form by emitting an array of selections
 *           closeModal = toggle function that closes the modal in the parent without adding new options */
export default {
  name: "LookupModal",
  components: {},
  emits: ["addSelection", "closeModal"],
  props: {
    categories: {
      type: Object,
      required: true,
    },
    /*Used on pages where multiple modals exist*/
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    max_input: {
      type: Number,
      required: false,
      default: 99,
    },
  },
  data() {
    return {
      show_text_input: false,
      show_category_dropdown: false,
      selected_category: "All",
      searching: false,
      filtered_categories: null,
      query: null,
      selected: [],
      og_category: null,
    };
  },
  created() {
    this.filtered_categories = this.categories;
  },
  watch: {
    query(new_query) {
      this.filterCategories(new_query);
    },
  },
  methods: {
    toggleCategory(index) {
      console.log(index);
      // TODO: Something tells me this is a no-no
      // eslint-disable-next-line vue/no-mutating-props
      this.categories[index].open = !this.categories[index].open;
      this.$forceUpdate();
    },
    toggleTextInput() {
      this.show_text_input = !this.show_text_input;
      if (this.show_text_input === false) {
        this.query = "";
      }
    },
    closeModal() {
      this.$emit("closeModal");
      this.query = "";
      this.filtered_categories = this.categories;
    },
    addSelected() {
      let selection = [];
      this.selected.forEach((item) => {
        selection.push(item);
      });
      this.$emit("addSelection", {
        selection: selection,
        index: this.index,
      });
    },
    pushItem: function (item) {
      this.selected.indexOf(item) >= 0
        ? this.selected.splice(this.selected.indexOf(item), 1)
        : this.selected.push(item);
    },
    setCategory(new_category) {
      this.selected_category = new_category;
    },
    filterCategories(query) {
      /* I don't hate this, but it could be cleaner? */
      if (query === "") {
        this.filtered_categories = this.categories;
      } else {
        this.filtered_categories = {};
        Object.keys(this.categories).forEach((category) => {
          if (
            category.toLowerCase().includes(query.toLowerCase()) ||
            this.categories[category].items.filter((item) => {
              return item.description
                .toLowerCase()
                .includes(query.toLowerCase());
            }).length > 0
          ) {
            this.filtered_categories[category] = {
              name: this.categories[category].name,
              open: true,
              items: this.categories[category].items.filter((item) => {
                return item.description
                  .toLowerCase()
                  .includes(query.toLowerCase());
              }),
            };
          }
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped></style>
