<template>
  <div class="py-3 w-3/4">
    <div
      v-for="(child, idx) in child_fields"
      :key="idx"
      class="grid grid-cols-2"
    >
      <div class="pt-4 font-bold">{{ child.name }}:</div>
      <div class="">
        <RenderField
          :type="child.type.toLowerCase()"
          :field_id="child.field_id"
          :label="child.name"
          :value="value_map ? value_map[child.field_id] : null"
          @emitNewInput="newChildInput"
        />
      </div>
    </div>
    <div class="grid grid-cols-2 border-t border-steel-gray">
      <div class="pt-4 font-bold">Total:</div>
      <div class="pt-4 font-bold">{{ preparation + event + travel }} hours</div>
    </div>
  </div>
</template>

<script>
import RenderField from "@/components/forms/RenderField";
export default {
  name: "SectionField",
  emits: ["input", "hiddenInput"],
  components: { RenderField },
  props: {
    child_fields: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    value_map: {
      type: Object,
      required: false,
    },
  },
  beforeCreate() {
    this.$options.components.RenderField = RenderField;
  },
  setup(props) {
    let child_value_map = {};
    props.value
      ? props.value.forEach((child) => {
          child_value_map[child.field_id] = child.value;
        })
      : null;
  },
  data() {
    return {
      preparation: 0,
      event: 0,
      travel: 0,
    };
  },
  computed: {
    sorted_child_fields: function () {
      // return sorted array of child fields by display_order
      let sorted = this.child_fields;
      sorted.sort((a, b) => {
        return a.display_order - b.display_order;
      });
      return sorted;
    },
  },
  methods: {
    newChildInput(value) {
      // console.log("newChildInput", value);
      if (value.label.toLowerCase() === "preparation") {
        this.preparation = value.value;
      } else if (
        value.label.toLowerCase() === "event" ||
        value.label.toLowerCase().includes("ppdp")
      ) {
        this.event = value.value;
      } else if (
        value.label.toLowerCase().includes("travel") ||
        value.label.toLowerCase().includes("spent")
      ) {
        this.travel = value.value;
      }
      this.$emit("hiddenInput", {
        value: value.value,
        label: value.label,
        field_id: value.field_id,
      });
    },
  },
};
</script>

<style scoped></style>
