<template>
  <div>
    <div
      class="fixed inset-0 w-full bg-white border border-slate-gray opacity-75 z-50"
    />
    <div class="fixed w-3/4 flex justify-center z-50">
      <div class="absolute bottom-0 right-1/2">
        <div class="border border-slate-gray w-72 bg-white box-style shadow-lg">
          <div class="thirdhead-text pb-3">Head's Up!</div>
          <div class="pb-5">
            {{ content }}
          </div>
          <div class="flex justify-between gap-3">
            <button
              aria-label="cancel_debrief"
              class="inline-block py-1 px-3 bg-purple hover:bg-dark-purple rounded-full text-white"
              @click="$router.go(-1)"
            >
              Go Back
            </button>
            <button
              aria-label="continue_debrief"
              class="inline-block py-1 px-3 bg-purple hover:bg-dark-purple rounded-full text-white"
              @click="$emit('continue')"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupWOverlay",
  emits: ["continue"],
  props: {
    content: {
      type: String,
      required: true,
    }
  }
};
</script>

<style scoped></style>
