<template>
  <div
    class="absolute text-left flex flex-col shadow-lg bg-cool-gray border-cool-gray rounded p-3 bottom-0 w-52 left-0 mb-8"
  >
    <div aria-label="info_header" class="text-dark-purple font-bold pb-1">{{ info_header }}</div>
    <div aria-label="info_text" class="pb-3">{{ info_text }}</div>
    <div class="relative text-5xl">
      <div class="absolute top-0 -mx-1.5 -mt-3 text-cool-gray">&#9662;</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  props: {
    info_header: {
      type: String,
      required: true,
    },
    info_text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
