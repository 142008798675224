<template>
  <div class="py-3">
    <textarea
      :class="{
        'w-3/4 text-base bg-white bg-clip-padding border border-steel-gray rounded': true,
      }"
      v-model="input"
      :id="label.replace(/[^A-Z0-9]+/ig, '_')"
    />
    <label class="sr-only" :for="label.replace(/[^A-Z0-9]+/ig, '_')">{{ label }}</label>
    <div class="py-1 text-xs w-3/4">
      <div v-if="wordsInInput() <= min_length && error_message" class="justify-self-start">
        <div class="rounded border border-error-border bg-error-bg text-error-text p-1">
          {{ error_message }}
        <!--TODO: This body needs to be part of a group, it is visible if error and the group is focused-->
        </div>
      </div>
      <div class="text-right">{{ wordsInInput() }} words</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextareaField",
  emits: ["input"],
  props: {
    label: {
      type: String,
      required: true,
    },
    min_length: {
      type: Number,
      default: null,
    },
    error_message: {
      type: String,
      default: null,
    },
    value: {
      required: false,
    }
  },
  data() {
    return {
      input: null,
    };
  },
  created() {
    this.input = this.value ? String(this.value) : "";
  },
  methods: {
    wordsInInput: function () {
      let count = 0;
      this.input.split(" ").forEach((w) => {
        if (w) {
          count++;
        }
      });
      return count;
    },
  },
  watch: {
    input(newValue) {
      this.$emit("input", { value: newValue, label: this.label, length: this.wordsInInput() });
    },
  },
};
</script>

<style scoped></style>
