<template>
  <div class="py-3">
    <Multiselect
      v-model="input"
      :classes="classes"
      :searchable="true"
      :close-on-select="true"
      :options="dyn_options.length > 0 ? dyn_options : options"
      :id="label.replace(/[^A-Z0-9]+/ig, '_')+'_Wrapper'"
      ref="multiselect"
    />
    <label class="sr-only" :for="label.replace(/[^A-Z0-9]+/ig, '_')">{{ label }}</label>
    <div v-for="content in child_fields" :key="content.label">
      <div v-if="content.parent_field_value === input" class="pt-3">
        <div class="thirdhead-text">{{ content.name }}</div>
        <div>{{ content.description }}</div>
        <RenderField
          :field_id="content.field_id"
          :type="content.hidden_type.toLowerCase()"
          :value="value_map ? value_map[content.field_id] : null"
          :child_fields="content.child_fields"
          :options="'options' in content ? content.options : null"
          :hidden_content="
            'options' in content && 'hidden_content' in content
              ? content.hidden_content
              : null
          "
          :label="content.name"
          @emitNewInput="newHiddenInput"
        />
      </div>
    </div>
    <div v-if="error_message">
      <div v-if="input.toLowerCase() === 'no'">
        <popup-w-overlay :content="error_message" @continue="toggleError"/>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import RenderField from "@/components/forms/RenderField";
import { useStore } from "vuex";
import PopupWOverlay from "@/components/displays/alert/PopupWOverlay";
export default {
  name: "SearchDropboxField",
  components: { PopupWOverlay, Multiselect, RenderField },
  emits: ["input", "hiddenInput"],
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    hidden_content: {
      type: Array,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select an option",
    },
    value: {
      required: false,
    },
    child_fields: {
      type: Array,
      required: false,
    },
    value_map: {
      type: Object,
      required: false,
    },
    error_message: {
      type: String,
      required: false,
    },
  },
  // Required for recursive component calling
  beforeCreate() {
    this.$options.components.RenderField = RenderField;
  },
  setup(props) {
    // add label to the options object for multiselect component
    let dyn_options = [];
    const store = useStore();
    if (!props.options || props.options.length <= 1) {
      if (props.label.toLowerCase().includes("experience")) {
        let entries = store.getters.experience_entries;
        entries.forEach((entry) => {
          // console.log(entry);
          dyn_options.push({
            label: `${entry.event_status} Experience`,
            value: `${entry.event_status} Experience`,
          });
        });
      } else if (props.label.toLowerCase().includes("template")) {
        // console.log("Debrief Template Selected");
        let templates = store.getters.lookup_debrief_templates;
        templates.forEach((template) => {
          dyn_options.push({
            label: template.name,
            value: template.name,
          });
        });
      }
    }
    props.options.map((option) => {
      option["label"] = option.input ? option.input : option.value;
    });
    return {
      dyn_options,
    }
  },
  data() {
    return {
      input: "",
      error: true,
      classes: {
        container:
          "relative w-2/3 flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none",
        containerDisabled: "cursor-default bg-gray-100",
        containerOpen: "rounded-b-none",
        containerOpenTop: "rounded-t-none",
        containerActive: "ring ring-green-500 ring-opacity-30",
        singleLabel:
          "flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border",
        singleLabelText:
          "overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full",
        multipleLabel:
          "flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5",
        search:
          "w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5",
        tags: "flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2",
        tag: "bg-purple text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap",
        tagDisabled: "pr-2 opacity-50",
        tagRemove:
          "flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group",
        tagRemoveIcon:
          "bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60",
        tagsSearchWrapper:
          "inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full",
        tagsSearch:
          "absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full",
        tagsSearchCopy: "invisible whitespace-pre-wrap inline-block h-px",
        placeholder:
          "flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400",
        caret:
          "bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none",
        caretOpen: "rotate-180 pointer-events-auto",
        clear:
          "pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80",
        clearIcon:
          "bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block",
        spinner:
          "bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0",
        dropdown:
          "max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b",
        dropdownTop:
          "-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t",
        dropdownHidden: "hidden",
        options: "flex flex-col p-0 m-0 list-none",
        optionsTop: "flex-col-reverse",
        group: "p-0 m-0",
        groupLabel:
          "flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal",
        groupLabelPointable: "cursor-pointer",
        groupLabelPointed: "bg-gray-300 text-gray-700",
        groupLabelSelected: "bg-purple text-white",
        groupLabelDisabled: "bg-gray-100 text-gray-300 cursor-not-allowed",
        groupLabelSelectedPointed: "bg-purple text-white opacity-90",
        groupLabelSelectedDisabled:
          "text-green-100 bg-purple bg-opacity-50 cursor-not-allowed",
        groupOptions: "p-0 m-0",
        option:
          "flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3",
        optionPointed: "text-white bg-purple",
        optionSelected: "text-white bg-purple",
        optionDisabled: "text-white cursor-not-allowed bg-slate-gray",
        optionSelectedPointed: "text-white bg-purple opacity-90",
        optionSelectedDisabled:
          "text-green-100 bg-purple bg-opacity-50 cursor-not-allowed",
        noOptions: "py-2 px-3 text-gray-600 bg-white text-left",
        noResults: "py-2 px-3 text-gray-600 bg-white text-left",
        fakeInput:
          "bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent",
        spacer: "h-9 py-px box-content",
      },
    };
  },
  created() {
    this.input = this.value ? this.value[0] : "";
  },
  mounted() {
    this.$refs.multiselect.input.id = this.label.replace(/[^A-Z0-9]+/ig, '_');
  },
  watch: {
    input(newValue) {
      this.$emit("input", {
        value: newValue,
        label: this.label,
      });
    },
    // watch for prop changes in parent
    options: function () {
      // console.log("options changed from", oldValue, "to", newValue);
    },
  },
  methods: {
    toggleError: function () {
      this.input = "";
    },
    newHiddenInput: function (newHiddenValue) {
      // console.log("newHiddenInput", newHiddenValue);
      this.$emit("hiddenInput", {
        value: newHiddenValue.value,
        label: newHiddenValue.label,
        field_id: newHiddenValue.field_id,
      });
    },
    checkForRideAlongExperience: function (exp, label) {
      if (
        exp === "Attend a ride along with a police officer" &&
        label === "Title of Other Attorney/Judge"
      ) {
        return "Rank of Police Officer";
      } else if (
        exp === "Attend a ride along with a police officer" &&
        label === "First Name of Other Attorney/Judge"
      ) {
        return "First Name of Police Officer";
      } else if (
        exp === "Attend a ride along with a police officer" &&
        label === "Last Name of Other Attorney/Judge"
      ) {
        return "Last Name of Police Officer";
      } else {
        return label;
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../node_modules/@vueform/multiselect/themes/tailwind.scss";
</style>
