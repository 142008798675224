<template>
  <div class="py-3">
    <div class="flex gap-8">
      <div class="flex-none pt-1 font-bold w-16">Time Spent:</div>
      <div class="flex-grow">
        <input
          type="text"
          class="rounded text-base w-16"
          placeholder="0.00"
          v-model="input"
          :id="label.replace(/[^A-Z0-9]+/ig, '_')"
        />
        <label class="sr-only" :for="label.replace(/[^A-Z0-9]+/ig, '_')">{{ label }}</label>
      </div>
    </div>
    <div class="py-1 flex text-xs text-steel-gray w-3/4">
      <div v-if="input % 0.25 !== 0" class="justify-self-start">
        <div class="rounded border border-error-border bg-error-bg text-error-text p-1">
          {{ error_message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeSingleField",
  emits: ["input"],
  props: {
    label: {
      type: String,
      required: true,
    },
    error_message: {
      type: String,
      default: "Please enter time in 0.25 hour increments.",
    },
  },
  data() {
    return {
      input: null,
    };
  },
  watch: {
    input: function (newValue) {
      this.$emit("input", { value: { event_time: newValue, prep_time: 0, travel_time: 0, }, label: this.label });
    },
  },
};
</script>

<style scoped></style>
