<template>
  <div class="py-3">
    <div class="pb-3 flex gap-10">
      <div class="flex-none pt-2 font-bold w-16">Preparation:</div>
      <div class="flex-grow">
        <input
          type="text"
          class="rounded border text-base w-16 p-2"
          placeholder="0.00"
          v-model="prep_time"
          :id="label.replace(/[^A-Z0-9]+/ig, '_') + '-prep_time'"
        />
        <label class="sr-only" :for="label.replace(/[^A-Z0-9]+/ig, '_') + '-prep_time'">{{
            label + "-prep_time"
          }}</label>
        <div class="flex flex-col text-xs text-steel-gray w-3/4">
          <div v-if="prep_time % 0.25 !== 0" class="justify-self-start pt-1">
            <div
              class="rounded border border-error-border bg-error-bg text-error-text p-1"
            >
              {{ error_message }}
            </div>
          </div>
          <div
            v-if="prep_message && prep_time"
            class="justify-self-start pt-1"
          >
            <div
              class="rounded border border-gray bg-light-gray text-dark-gray p-1"
            >
              {{ prep_message }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-3 flex gap-10">
      <div class="flex-none pt-2 font-bold w-16">Travel:</div>
      <div class="flex-grow">
        <input
          type="text"
          class="rounded border text-base w-16 p-2"
          placeholder="0.00"
          v-model="travel_time"
          :id="label + '-travel_time'"
        />
        <label class="sr-only" :for="label + '-travel_time'">{{
            label + "-travel_time"
          }}</label>
        <div class="flex text-xs text-steel-gray w-3/4">
          <div v-if="travel_time % 0.25 !== 0" class="justify-self-start pt-1">
            <div
              class="rounded border border-error-border bg-error-bg text-error-text p-1"
            >
              {{ error_message }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-3 flex gap-10">
      <div class="flex-none pt-2 font-bold w-16">Event:</div>
      <div class="flex-grow">
        <input
          type="text"
          class="rounded border p-2 text-base w-16"
          placeholder="0.00"
          v-model="event_time"
          :id="label + '-event_time'"
        />
        <label class="sr-only" :for="label + '-event_time'">{{
            label + "-event_time"
          }}</label>
        <div class="flex text-xs text-steel-gray w-3/4">
          <div v-if="event_time % 0.25 !== 0" class="justify-self-start pt-1">
            <div
              class="rounded border border-error-border bg-error-bg text-error-text p-1"
            >
              {{ error_message }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-6 border-t w-1/2 border-steel-gray">
      <div class="flex-none pt-4 font-bold w-16">Total:</div>
      <div class="flex-grow pt-4 w-16 px-6">
        {{ +prep_time + +travel_time + +event_time }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeField",
  emits: ["input"],
  props: {
    label: {
      type: String,
      required: true,
    },
    error_message: {
      type: String,
      default: "Please enter time in 0.25 hour increments.",
    },
    prep_message: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      prep_time: null,
      travel_time: null,
      event_time: null,
    };
  },
  watch: {
    prep_time: function (val) {
      this.$emit("input", {
        value: {
          prep_time: val,
          travel_time: this.travel_time,
          event_time: this.event_time,
        },
        label: this.label,
      });
    },
    travel_time: function (val) {
      this.$emit("input", {
        value: {
          prep_time: this.prep_time,
          travel_time: val,
          event_time: this.event_time,
        },
        label: this.label,
      });
    },
    event_time: function (val) {
      this.$emit("input", {
        value: {
          prep_time: this.prep_time,
          travel_time: this.travel_time,
          event_time: val,
        },
        label: this.label,
      });
    },
  },
};
</script>

<style scoped></style>
